







































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import UploadRepository from '@/api/repositories/Upload.repository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {shipOwnerStoreActions, shipOwnerStoreGetter} from '@/store/shipOwner.store';
import ShipOwner from '@/models/ShipOwner.model';
import {UserRole} from '@/models/User.model';

const ShipOwnerStore = namespace('shipOwner');
const uploadRepository: UploadRepository = RepositoryFactory.get('upload');

@Component({
  components: {
    ShipOwnerTableComponent: () => import(
        /* webpackChunkName: "ShipOwnerTableComponent" */
        '@/components/settings/ShipOwnerTable.component.vue'),
  }
})
export default class SettingsShipsView extends Vue {

  @ShipOwnerStore.Action(shipOwnerStoreActions.GET_ALL)
  public getAllShipOwners!: (payload?: { filterText: string }) => Promise<ShipOwner[]>;

  @ShipOwnerStore.Getter(shipOwnerStoreGetter.LIST_ALL)
  public allShipOwners!: ShipOwner[];

  public searchValue: string = '';
  public selectedFile: File | null = null;

  public async created() {
    await this.getAllShipOwners();
  }

  public async uploadCSVClicked() {

    try {
      await uploadRepository.uploadShipOwnerCsv({file: this.selectedFile!});
      Vue.notifySuccessSimplified('SETTINGS.SHIPOWNER_VIEW.NOTIFICATIONS.SAVED_DATA');
    } catch (e) {
      Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } finally {
      await this.getAllShipOwners();
    }
  }

  public openFileSelection() {
    (this.$refs.fileInput as HTMLElement).click();
  }

  public dragOver(event: Event) {
    event.preventDefault();
    (this.$refs.dropZone as HTMLElement).classList.add('dragEnter');
  }

  public dragLeave(event: Event) {
    event.preventDefault();
    (this.$refs.dropZone as HTMLElement).classList.remove('dragEnter');
  }

  public setSelectedFile() {
    this.selectedFile = (this.$refs.fileInput as HTMLInputElement).files![0];
    this.validateFile();
  }

  public dropEvent(event: any) {
    event.preventDefault();
    (this.$refs.fileInput as HTMLInputElement).files = event.dataTransfer.files;
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
    }
    (this.$refs.dropZone as HTMLElement).classList.remove('dragEnter');
    this.validateFile();
  }

  public validateFile() {
    if (this.selectedFile && !this.selectedFile.name.endsWith('.csv')) {
      this.selectedFile = null;
      Vue.notifyErrorSimplified('ERRORS.ONLY_CSV_ALLOWED');
    }
  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.PLANER)
  }
}

